var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "place-input-wrapper", class: { "show-map": _vm.showMap } },
    [
      _c("label", { attrs: { id: "label" } }, [
        _vm._v("\n    " + _vm._s(_vm.location || "Plaats") + "\n  "),
      ]),
      _vm._v(" "),
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.query,
            expression: "query",
          },
        ],
        ref: "input",
        staticClass: "location-input",
        attrs: {
          id: "location",
          type: "text",
          placeholder: "Plaats",
          autocomplete: "off",
        },
        domProps: { value: _vm.query },
        on: {
          focus: _vm.onFocus,
          blur: _vm.onBlur,
          input: function ($event) {
            if ($event.target.composing) {
              return
            }
            _vm.query = $event.target.value
          },
        },
      }),
      _vm._v(" "),
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.location,
            expression: "location",
          },
        ],
        attrs: { type: "hidden", name: "location" },
        domProps: { value: _vm.location },
        on: {
          input: function ($event) {
            if ($event.target.composing) {
              return
            }
            _vm.location = $event.target.value
          },
        },
      }),
      _vm._v(" "),
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.value,
            expression: "value",
          },
        ],
        ref: "value",
        attrs: { type: "hidden", name: "latlng" },
        domProps: { value: _vm.value },
        on: {
          input: function ($event) {
            if ($event.target.composing) {
              return
            }
            _vm.value = $event.target.value
          },
        },
      }),
      _vm._v(" "),
      _vm.focussed
        ? _c(
            "ul",
            {
              staticClass: "c-list-suggestions",
              class: { loading: _vm.loading, empty: _vm.options.length <= 0 },
            },
            [
              _c("li", { staticClass: "c-suggestion-helptext" }, [
                _vm._v("\n      Waar zoek jij een stek?\n    "),
              ]),
              _vm._v(" "),
              _vm._l(_vm.options, function (suggestion, index) {
                return _c("PlaceInputSuggestion", {
                  key: "suggestion-" + index,
                  attrs: { suggestion: suggestion },
                  on: {
                    pick: function ($event) {
                      return _vm.pickSuggestion(suggestion)
                    },
                  },
                })
              }),
              _vm._v(" "),
              _vm.loading
                ? _c("li", { staticClass: "c-suggestion-loading" }, [
                    _vm._v("\n      Aan het laden...\n    "),
                  ])
                : _vm._e(),
              _vm._v(" "),
              !_vm.loading && _vm.query && _vm.options.length <= 0
                ? _c("li", { staticClass: "c-suggestion-no-results" }, [
                    _vm._v("\n      Niets gevonden.\n    "),
                  ])
                : _vm._e(),
            ],
            2
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.showMap && _vm.center
        ? _c(
            "GmapMap",
            {
              staticStyle: { width: "100%", height: "400px" },
              attrs: { center: _vm.mapCenter, zoom: 14 },
            },
            [
              _vm.valueAsLatLng
                ? _c("GmapMarker", {
                    attrs: { position: _vm.valueAsLatLng, draggable: true },
                    on: {
                      click: function ($event) {
                        _vm.center = _vm.valueAsLatLng
                      },
                      drag: _vm.updateFromMarker,
                    },
                  })
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }