var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "li",
    {
      staticClass: "c-suggestion",
      on: {
        click: function ($event) {
          return _vm.$emit("pick", _vm.suggestion)
        },
      },
    },
    [
      _vm._l(_vm.labels, function (label, index) {
        return [
          index > 0 ? [_vm._v(", ")] : _vm._e(),
          _vm._v(" "),
          _c("span", { class: "c-suggestion-label-" + label.type }, [
            _vm._v(_vm._s(label.text)),
          ]),
        ]
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }